import { t } from "@lingui/core/macro";
import { DEFAULT_DATE_FORMAT } from '@integration-frontends/core';
import { Attachment } from '@integration-frontends/integration/core/model';
import { format } from 'date-fns';
import React from 'react';

export interface InformationProps {
  attachment: Attachment;
}

export function AttachmentInformation({ attachment }: InformationProps) {
  return (
    <div className="flex w-full flex-col">
      <span>{attachment?.name}</span>
      <div className="gap-sm flex justify-between overflow-auto">
        <span className="whitespace-nowrap">{attachment?.extension.toLocaleUpperCase()}</span>
        <span className="whitespace-nowrap">{Math.round(attachment?.sizeInBytes / 1000)} KB</span>
        <span className="whitespace-nowrap">
          {attachment?.dimensions.width} x {attachment?.dimensions.height} px
        </span>
        {/*<span>RGB</span>*/}
        <span>{format(attachment.updatedAt, DEFAULT_DATE_FORMAT)}</span>
      </div>
    </div>
  );
}

import { t } from "@lingui/core/macro";
import { Trans } from "@lingui/react/macro";
import { Popover } from '@headlessui/react';
import { BFButton, BFHR, BFPanel, ButtonType, IconClose } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  applyCropOptions,
  attachmentDeselected,
  attachmentEntitySelectors,
  attachmentSelected,
  createFreeformCropOptions,
  CropOptions,
  enteredAttachmentDetails,
  getCropDimensions,
  getCropOffsets,
  initiatePlaceAttachment,
  IntegrationRootState,
  ResizingOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectActionType,
  selectAttachmentSelectors,
  SelectionType,
} from '@integration-frontends/integration/core/application';
import {
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  AttachmentVariant,
  DimensionType,
  IAttachmentTransformService,
  isKnownMediaType,
  KnownMediaType,
  MediaType,
} from '@integration-frontends/integration/core/model';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, Nav } from '../../../common/layout';
import { AttachmentSelectorFeature } from '../../../features';
import { AttachmentCropper } from './attachment-cropper';
import { AttachmentDetails } from './attachment-details';
import { AttachmentInformation } from './attachment-information';
import { AttachmentTags } from './attachment-tags';
import { CustomFields } from './custom-fields';
import { PlacementOptions } from '../asset-details-page/placement-options';
import { PrimaryImage } from './primary-image';
import { useAttachmentSelectorNavigation } from '../../navigation';
import { AttachmentDetailsActions } from './attachment-details-actions';
import { BFBackButton } from '../../../common/back-button';
import { useFeatures } from '../../common/use-features';

export interface AttachmentDetailsProps {
  attachmentId: string;
  containerId?: string;
}

// TODO: This component currently handles a bit too much. Should consider moving stateful logic into a hook.
export function AttachmentDetailsPage({ attachmentId, containerId }: AttachmentDetailsProps) {
  const dispatch = useDispatch();
  const { hasFeature } = useFeatures();
  const selectOptions = DI_CONTAINER.get(SELECT_ATTACHMENT_OPTIONS_TOKEN);
  const selectActionType: SelectActionType = selectOptions.actionType;
  const selectionType: SelectionType = selectOptions.selectionType;
  const selectedAttachments = useSelector(selectAttachmentSelectors.selectedAttachments);
  const [showCropper, setShowCropper] = useState(false);
  const attachment = useSelector((state: IntegrationRootState) =>
    attachmentEntitySelectors.selectById(state, attachment.id),
  );
  const [modifiedAttachment, setModifiedAttachment] = useState<AttachmentVariant>(null);
  const [cropOptions, setCropOptions] = useState<CropOptions>(createFreeformCropOptions());
  const [userResizingOptions, setUserResizingOptions] = useState<ResizingOptions>(
    attachment
      ? {
          initialDimensions: attachment.dimensions,
          newDimensions: attachment.dimensions,
          lockAspectRatio: true,
        }
      : null,
  );
  const [mediaType, setMediaType] = useState<MediaType>(attachment?.mediaType);
  const navigation = useAttachmentSelectorNavigation();

  useEffect(() => {
    dispatch(enteredAttachmentDetails({ attachmentId }));
  }, [attachmentId]);

  // init userResizingOptions once we receive the attachment data
  useEffect(() => {
    if (attachment && !userResizingOptions) {
      setUserResizingOptions({
        initialDimensions: attachment.dimensions,
        newDimensions: attachment.dimensions,
        lockAspectRatio: true,
      });
    }
  }, [attachment]);

  function mediaTypeChanged(type: KnownMediaType) {
    setMediaType(type);
    attachmentModified(userResizingOptions, cropOptions, type);
  }

  function resized(resizingOptions: ResizingOptions) {
    setUserResizingOptions(resizingOptions);
    attachmentModified(resizingOptions, cropOptions, mediaType);
  }

  function croppingSaved(options: CropOptions) {
    const newResizingOptions = applyCropOptions(userResizingOptions, options);
    setUserResizingOptions(newResizingOptions);
    setCropOptions(options);
    setShowCropper(false);
    attachmentModified(newResizingOptions, options, mediaType);
  }

  async function attachmentModified(
    resizingOptions: ResizingOptions,
    cropOptions: CropOptions,
    mediaType: MediaType,
  ) {
    const { width, height } = getCropDimensions(attachment.dimensions, cropOptions);
    const { offsetX, offsetY } = getCropOffsets(attachment.dimensions, cropOptions);

    const attachmentVariantService: IAttachmentTransformService = DI_CONTAINER.get(
      ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
    );

    setModifiedAttachment(
      await attachmentVariantService.transform(attachment, {
        size: resizingOptions.newDimensions,
        crop: { width, height, offsetX, offsetY, type: DimensionType.Absolute },
        mediaType,
      }),
    );
  }

  function backClicked() {
    if (containerId) {
      navigation.goToAssetDetails(attachment.assetId, containerId);
    } else {
      navigation.goToContainerSelector();
    }
  }

  function place() {
    if (selectionType === SelectionType.Multi) {
      !selectedAttachments.includes(attachment.id)
        ? dispatch(attachmentSelected({ attachment: modifiedAttachment || attachment }))
        : dispatch(attachmentDeselected({ attachment: attachment }));
    } else {
      dispatch(initiatePlaceAttachment({ attachment: modifiedAttachment || attachment }));
    }
  }

  if (!attachment) return null;

  return (
    <div data-testid="attachment-details-page" className="h-full overflow-y-auto">
      <Nav>
        {containerId ? (
          <BFBackButton onClick={backClicked} />
        ) : (
          <BFButton
            buttonType={ButtonType.Secondary}
            className="flex items-center justify-center"
            style={{ width: 40, height: 40 }}
            onClick={backClicked}
          >
            <IconClose />
          </BFButton>
        )}
      </Nav>

      <Body>
        <div>
          {showCropper ? (
            <AttachmentCropper
              attachment={attachment}
              cropOptions={cropOptions}
              onCancel={() => setShowCropper(false)}
              onSave={croppingSaved}
            />
          ) : (
            <>
              <div className="mb-xxl">
                <div className="flex justify-center">
                  <PrimaryImage attachment={modifiedAttachment || attachment} className="mb-lg" />
                </div>
                <div>
                  <div className="gap-md flex flex-row justify-center">
                    {hasFeature(AttachmentSelectorFeature.PlacementOptions) && (
                      <Popover className="">
                        <Popover.Button as="div">
                          <BFButton
                            data-testid="placement-options-btn"
                            buttonType={ButtonType.Secondary}
                            style={{ minWidth: '150px' }}
                          >
                            <Trans>More Options</Trans>
                          </BFButton>
                        </Popover.Button>

                        <Popover.Panel className="pt-sm absolute" style={{ width: 270 }}>
                          <BFPanel className="bg-bf-white p-lg">
                            <PlacementOptions
                              attachment={modifiedAttachment || attachment}
                              userResizingOptions={userResizingOptions}
                              onMediaTypeChange={mediaTypeChanged}
                              onResize={resized}
                              selectedMediaType={isKnownMediaType(mediaType) ? mediaType : null}
                            />
                          </BFPanel>
                        </Popover.Panel>
                      </Popover>
                    )}
                    <AttachmentDetailsActions
                      selectAction={
                        selectActionType === SelectActionType.Place ? t`Place` : t`Select`
                      }
                      onSelection={place}
                      attachment={modifiedAttachment || attachment}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-md">
                <AttachmentInformation attachment={attachment} />
              </div>

              <BFHR className="mb-md" />

              <div className="mb-md">
                <AttachmentDetails attachment={attachment} />
              </div>

              <BFHR className="mb-md" />

              <CustomFields attachment={attachment} />

              <AttachmentTags attachment={attachment} />
            </>
          )}
        </div>
      </Body>
    </div>
  );
}

import { t } from "@lingui/core/macro";
import React from 'react';
import { BFStandardBanner } from '../standard-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Banner,
  selectOpenBanners,
  closeBanner,
} from '@integration-frontends/common/notifications';
import { useLingui } from '@lingui/react';

export const BannerNotifications = () => {
  const banners = useSelector(selectOpenBanners);
  const dispatch = useDispatch();
  const { _: translate } = useLingui();

  const handleDismiss = (banner, id) => {
    // Perform the onClick action from dismiss button (if available)
    if (banner?.bannerContent?.dismissButtonAction) {
      banner.bannerContent.dismissButtonAction();
    }
    dispatch(closeBanner({ id }));
  };

  return (
    <>
      {banners.map((banner: Banner) => (
        <BFStandardBanner
          key={banner.index}
          bannerProps={{ type: banner.type }}
          contentProps={{ contentText: translate(banner?.bannerContent?.contentText) }}
          linkProps={{
            linkText: banner?.bannerContent?.linkText,
            linkHref: banner?.bannerContent?.linkHref,
          }}
          actionProps={{
            actionText: banner?.bannerContent?.actionText,
            onClick: banner?.bannerContent?.actionTextAction,
          }}
          dismissButtonProps={{
            'aria-label':
              banner.bannerContent.displayDismissButton !== false && t`banner dismiss button`,
            onClick: () =>
              banner.bannerContent.displayDismissButton !== false &&
              handleDismiss(banner, banner.id),
          }}
        />
      ))}
    </>
  );
};

import { Trans } from "@lingui/react/macro";
import { assetTagEntitySelectors } from '@integration-frontends/integration/core/application';
import { Attachment } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useSelector } from 'react-redux';
import { SectionTitle } from '../../../common/layout';
import { Tags } from '../../common/tags';

export interface TagsProps {
  attachment: Attachment;
}

export function AttachmentTags({ attachment }: TagsProps) {
  const tags = useSelector(assetTagEntitySelectors.byAssetId(attachment.assetId));

  if (tags.length <= 0) return null;
  return (
    <div className="mb-md">
      <SectionTitle>
        <Trans>Tags</Trans>
      </SectionTitle>
      <Tags tags={tags} />
    </div>
  );
}

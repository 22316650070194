import { t } from "@lingui/core/macro";
import React from 'react';
import { BFStandardToast } from '../standard-components';
import { useDispatch, useSelector } from 'react-redux';

import { Toast, closeToast, selectOpenToasts } from '@integration-frontends/common/notifications';
import { useLingui } from '@lingui/react';

export const ToastNotifications = () => {
  const toasts = useSelector(selectOpenToasts);
  const dispatch = useDispatch();
  const { _: translate } = useLingui();

  const handleDismiss = (toastContent, id) => {
    if (toastContent.dismissButtonAction) {
      toastContent.dismissButtonAction();
    }
    dispatch(closeToast({ id }));
  };

  return (
    <>
      {toasts.map(({ toastContent, toastState, type }: Toast, i) => {
        const interactiveToastContent = toastContent.interactiveToastContent || {};
        const toastProps = {
          type,
          message: translate(toastContent.contentText),
        };
        const toastContentProps = toastContent.children
          ? { children: toastContent.children }
          : undefined;
        const linkProps =
          interactiveToastContent.linkText && interactiveToastContent.linkHref
            ? {
                linkText: interactiveToastContent.linkText,
                linkHref: interactiveToastContent.linkHref,
              }
            : undefined;
        const toastActionProps =
          interactiveToastContent.actionText && interactiveToastContent.actionTextAction
            ? {
                actionText: interactiveToastContent.actionText,
                onClick: interactiveToastContent.actionTextAction,
              }
            : undefined;
        const toastDismissButtonProps = {
          'aria-label': t`toast dismiss button`,
          dismissToast: () => handleDismiss(toastContent, toastState?.id),
        };
        return (
          <BFStandardToast
            key={toastState?.index || i}
            toastProps={toastProps}
            toastContentProps={toastContentProps}
            linkProps={linkProps}
            toastActionProps={toastActionProps}
            toastDismissButtonProps={toastDismissButtonProps}
          />
        );
      })}
    </>
  );
};
